<template>
  <div v-loading="isLoading" class="faq-page page">
    <div class="faq-page__wrapper">
      <div class="faq-page__left">
        <div class="faq-page__title" :class="{ 'locale-usa': locale === 'USA' }">
          <Title :text="$t('faq.title')" />
        </div>
        <div 
          v-if="activeQuestion" 
          class="faq-page__content" 
          v-html="activeQuestion.text" 
        />
      </div>
      
      <div class="faq-page__sidebar-wrapper">
        <div class="faq-page__sidebar">
          <div 
            v-for="(question, index) in filteredQuestions" 
            :key="index" 
            class="faq-page__sidebar-item"
            :class="{ 'is-active': activeQuestion && activeQuestion.id === question.id }" 
            @click="setActiveQuestion(question)"
          >
            {{ question.title }}
          </div>
        </div>
        <!-- <div class="faq-page__pagination">
          <el-pagination
            v-if="pagination && pagination.total >= 10"
            layout="prev, pager, next"
            :total="pagination.total"
            :page-size="10"
            :current-page="pagination.currentPage"
            @current-change="fetchNewFaq($event)"
          />
        </div> -->
      </div>
    </div>
    <!-- <div v-if="locale === 'USA'"> -->
      <!-- <div class="faq-page__title">
        <Title :text="$t('faq.title')" />
      </div> -->
      <!-- <div class="faq-page__wrapper">
        <div class="faq-page__left">
          <div class="faq-page__title" :class="{ 'locale-usa': locale === 'USA' }">
            <Title :text="$t('faq.title')" />
          </div>
          <div class="faq-page__content" v-html="activeContent" />
          <router-view></router-view>
        </div>
        <div>
          <div class="faq-page__sidebar">
            <ul class="faq-page__contents">
              <li v-for="(subLink, key) in faqLinks" :key="key">
                <router-link :to="subLink.path">
                  {{subLink.title}}
                </router-link>
              </li>
            </ul>
          </div>
          <div class="faq-page__pagination">
            <el-pagination
              v-if="pagination && pagination.total >= 10"
              layout="prev, pager, next"
              :total="pagination.total"
              :page-size="10"
              :current-page="pagination.currentPage"
              @current-change="fetchNewFaq($event)"
            />
          </div>
        </div>
        
      </div> -->
    <!-- </div> -->
  </div>
</template>

<script>
import Title from "@/components/elements/Title";
import { mapActions, mapState, mapMutations } from 'vuex'

export default {
  name: "Support",
  components: {
    Title,
  },
  data() {
    return {
      locale: process.env.VUE_APP_LOCALE,
      lang: this.$i18n._vm.locale,
      activeQuestion: null,
      activeTab: 0,
      // tabs: [
      //   {
      //     name: "Возврат денежных средств для физлиц",
      //     nameEn: "Refunds for individuals",
      //     content: `
            // <h3>Возврат денежных средств для физлиц</h3>
            // <ol>
            //   <li>Деньги возвращаются на ту карту/кошелек, с которого производилась оплата.</li>
            //   <li>Возврату подлежит вся сумма, оставшаяся на балансе аккаунта ProfitAds в рамках пополнения. Предварительно мы возвращаем доступные средства с рекламных систем на баланс ProfitAds. Частичный возврат средств не осуществляется</li>
            //   <li>Срок возврата зависит от вашего банка. Возврат может занять от 1 до 14 дней.</li>
            //   <li>Если в течение этого месяца вы пополняли баланс в рекламных системах или у вас были расходы на рекламу, то мы сможем оформить возврат только после того, как получим данные о расходах от рекламных систем. Обычно мы получаем их к середине следующего месяца.</li>
            //   <li>После того, как вы подтвердите возврат, деньги будут заморожены на балансе ProfitAds до того, как вернутся на карту или кошелек.</li>
            // </ol>
            // <p>Для осуществления возврата вам необходимо будет заполнить заявление и прислать нам подписанный скан/фотографию в хорошем качестве на электронную почту <a href="mailto:finance@profitads.ru">finance@profitads.ru</a></p>
      //     `,
      //     contentEN: `
      //       <h3>Refunds for individuals</h3>
      //       <ol>
      //         <li>The money is returned to the card / wallet from which the payment was made.</li>
      //         <li>The entire amount remaining on the balance of the ProfitAds account as part of the replenishment is subject to a refund. Previously, we return the available funds from advertising systems to the balance of ProfitAds. No partial refunds</li>
      //         <li>The return period depends on your bank. Returns may take from 1 to 14 days.</li>
      //         <li>If during this month you topped up the balance in advertising systems or you had expenses on advertising, then we will be able to issue a refund only after we receive data on expenses from advertising systems. We usually receive them by the middle of the next month.</li>
      //         <li>After you confirm the return, the money will be frozen on the ProfitAds balance before being returned to the card or wallet.</li>
      //       </ol>
      //       <p>To make a return, you will need to fill out an application and send us a signed scan / photo in good quality by e-mail <a href="mailto:finance@profitads.ru">finance@profitads.ru</a></p>
          
      //     `
      //   }
      // ],
      // faqLinks: [
      //   {path:'how-add', title:'How to add an advertising account?'},
      //   {path:'how-replenish-total', title:'How do I replenish the total balance of my business account (Deposit)?'},
      //   {path:'how-replenish-balance', title:'How to replenish the balance of an advertising account?'},
      //   {path:'how-withdraw', title:'How to withdraw balance from an advertising account / How to transfer funds from one advertising account to another?'},
      //   {path:'how-commission-terms', title:'How to know the terms of commissions of advertising platforms?'},
      //   {path:'how-cashback-terms', title:'How do I know the terms of cashback?'},
      //   {path:'how-invoice', title:'How to get an invoice for payment?'}
      // ]
    };
  },
  computed: {
    ...mapState('faq', ['pagination', 'questions', 'isLoading']),
    // getLang() {
    //   let language = this.$i18n._vm.locale
    //   return language
    // },
    // activeContent() {
    //   if (this.getLang === 'ru') {
    //     return this.tabs[this.activeTab].content;
    //   }
    //   return this.tabs[this.activeTab].contentEN;
    // },
    filteredQuestions() {
      return this.questions.filter(question => question.active === true)
    }
  },
  methods: {
    ...mapActions('faq', ['fetchFaq']),
    ...mapMutations('faq', ['clearPagination']),
    fetchNewFaq(page = 1) {
      // return this.fetchFaq({ params: { per_page: 10, page: page } })
      return this.fetchFaq()
    },
    setActiveQuestion(question) {
      this.activeQuestion = question
    }
  },
  created() {
    this.fetchNewFaq().then((response) => {
      const questions = response.data
      if (questions && questions.length) {
        this.setActiveQuestion(questions[0])
      }
    })
  },
  destroyed() {
    this.clearPagination()
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/helpers/variables";
@import "@/assets/styles/helpers/mixin";

.faq-page {
  &__pagination {
    display: flex;
    justify-content: center;
    margin-top: 2rem;

    .el-pagination .btn-next,
    .el-pagination .btn-prev,
    .el-pager li {
      background: transparent;
    }
  }

  &>.faq-page__title {
    display: none;

    @include below(980px) {
      display: block;
    }
  }

  .router-link-exact-active {
    color: var(--primary);
  }

  &__contents {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    >li {
      list-style: none;
      line-height: normal;
      a {
        display: block;
      }
    }
  }

  &__title {
    margin-bottom: 3.2rem;

    &.locale-usa {
      &.locale-usa {
        font-family: 'Gilroy';
        font-style: normal;

        .title {
          font-weight: 700;
          font-size: 3.2rem;
        }
      }
    }
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;

    @include below(980px) {
      flex-direction: column-reverse;
    }
  }

  &__left {
    width: 100%;
    max-width: 70rem;
    padding-right: 2rem;

    &>.faq-page__title {
      @include below(980px) {
        display: none;
      }
    }
  }

  &__content {
    font-size: 1.6rem;

    h3 {
      margin-bottom: 2rem;
      font-size: 2rem;
    }

    ul,
    ol {
      padding-left: 2rem;

      li {
        margin: 0 0 1.5rem;
      }
    }

    a {
      font-weight: 700;
    }

    p {
      margin: 0 0 1.5rem;
    }
  }

  &__sidebar-wrapper {
    display: flex;
    flex-direction: column;
  }

  &__sidebar {
    width: 37rem;
    padding: 2.4rem 3rem;
    background: #ffffff;
    box-shadow: 0px 5px 10px rgba(158, 158, 158, 0.1);
    border-radius: 6px;
    height: 100%; 

    @include below(980px) {
      width: 100%;
      margin-bottom: 2rem;
    }

    &-item {
      font-weight: 500;
      font-size: 1.6rem;
      margin-bottom: 2rem;
      cursor: pointer;

      &.is-active {
        color: var(--primary);
        position: relative;

        &:before {
          content: "-";
          color: var(--primary);
          display: block;
          position: absolute;
          top: 50%;
          left: 0;
          transform: translate(-10px, -50%);
        }
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
</style>
